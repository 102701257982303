<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3 pe-md-10 mb-10 mb-md-0">
              <kta-preview :src="imageSrc" />
              <hr />
              <div v-if="detailData">
                <h5>History Status</h5>
                <div v-for="(s, i) in detailData.card_statuses" :key="i">
                  <span class="fw-bold">{{ s.status }}</span>
                  <span class="font-weight-lighter font-size-xs">
                    @{{ s.createdAt }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <status-kta :status="cardStatus" />
              <kta-detail :initial="detailData" />
            </div>
          </div>
        </div>

        <!--begin::Card body-->
        <div class="card-body">
          <h3>Detail Non-Aktif KTA</h3>
          <hr />
          <div class="form-group">
            <label class="form-label"> Status </label>
            <div class="input-group">
              <select class="form-select" v-model="status">
                <option value="" disabled selected>Silahkan Pilih</option>
                <option
                  v-for="(item, index) in statusOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group mt-5">
            <label class="form-label"> Keterangan </label>
            <textarea class="form-control" v-model="keterangan"> </textarea>
          </div>

          <div class="form-group mt-5 text-right">
            <button class="btn btn-success float-right" @click="handleFormData">
              Submit
            </button>
          </div>
        </div>

        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import KtaPreview from "@/components/kta/KtaPreview.vue";
import KtaDetail from "@/components/kta/KtaDetail.vue";
import StatusKta from "@/components/kta/StatusKta.vue";

// import FormGroup from "@/components/FormGroup.vue";
// import Container from "@/components/Container.vue";

// import { toRaw } from "vue";
import Service from "../../services/base.service";
// import ApprovalSevice from "../../services/approval.service";

export default {
  components: {
    KtaPreview,
    StatusKta,
    KtaDetail,

    // FormGroup,
    // Container,
  },
  data() {
    return {
      detailData: null,
      status: null,
      keterangan: "",
      imageSrc: null,
      statusOptions: [
        { label: "Blokir", value: "BLOKIR" },
        { label: "Hilang", value: "HILANG" },
        { label: "Rusak", value: "RUSAK" },
        { label: "Aktif", value: "AKTIF" },
      ],
      biodata: [
        {
          0: [
            {
              label: "Status",
              data: [
                { label: "Blokir", value: "BLOKIR" },
                { label: "Hilang", value: "HILANG" },
                { label: "Rusak", value: "RUSAK" },
                { label: "Aktif", value: "AKTIF" },
              ],
              value: "BLOKIR",
              type: "select",
            },
            { label: "Keterangan", value: "", type: "text" },
          ],
        },
      ],
    };
  },
  computed: {
    cardStatus() {
      let valid = null;
      if (this.detailData) {
        switch (this.detailData.status) {
          case "BLOKIR":
            valid = "block";
            break;
          case "HILANG":
            valid = "lost";
            break;
          case "RUSAK":
            valid = "block";
            break;

          default:
            valid = "valid";
            break;
        }
      }
      return valid;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  methods: {
    handleFormData() {
      this.updateData();
    },
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("card");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.detailData = data;
        if (data.attachments && data.attachments.length > 0) {
          const DownloadService = new Service("attachment/download");
          const img = data.attachments[0];
          DownloadService.downloadFile(img.id).then((blob) => {
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(blob);
            this.imageSrc = imageUrl;
          });
        }
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async updateData() {
      this.loading = true;
      try {
        const BaseService = new Service("card");
        const { data } = await BaseService.updateData(this.$route.params.id, {
          status: this.status,
          note: this.keterangan,
        });
        console.log(data);
        this.fetchData();
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
